/**
 * Suppress warnings for `dangerouslySetInnerHTML`.
 * HTML is generated by 'gatsby-transformer-remark'
 */
/* eslint-disable react/no-danger */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line react/forbid-prop-types
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Typography from 'components/Typography'
import Button from 'components/Button'
import ButtonIconMove from 'components/ButtonIconMove'
import Hero from 'components/Hero'
import ButtonGroup from 'components/ButtonGroup'
import BillboardInfoOne from 'components/BillboardInfoOne'
import BillboardInfoTwo from 'components/BillboardInfoTwo'
import BillboardImageInfoOne from 'components/BillboardImageInfoOne'
import Cta from 'components/Cta'
import RedirectLink from 'components/RedirectLink'
import SlickSLider from 'components/SlickSlider'
import MarkdownTemplate from 'components/MarkdownTemplate'
import SvgIcon from 'components/SvgIcon'
import IconCall from 'icons/inline/icon-call.svg'
import '../../css/main.scss'

import * as styles from './styles.module.scss'
import useUtagView from '../../hooks/useUtagView'

const HomeTemplate = ({ data, pageContext, location }) => {
  useUtagView();
  return (
    <>
      <SEO
        title={data.pageData.frontmatter.metaTitle}
        description={`${data.pageData.frontmatter.metaDescription}`}
      />
      <Layout
        mainClass="home-page"
        headerBtn={data.pageData.frontmatter.headerBtn}
        headerBtnHref={data.pageData.frontmatter.heroHref}
        pathname={location.pathname}
        langCode={pageContext.lang}
      >
        <Hero img={data.pageData.frontmatter.img} iconMore>
          <Typography variant="h1" isDisplay className="t-m">
            {data.pageData.frontmatter.title}
          </Typography>
          <Typography variant="txt1" isLead>
            {data.pageData.frontmatter.subtitle}
          </Typography>
          <ButtonGroup className="t-0 b-0">
            <div className="btn-group__item t-l b-l">
              <ButtonIconMove variant="primary" size="l" sizeResponsive={['m-l', 's-s']} external href={data.pageData.frontmatter.heroHref}>
                {data.pageData.frontmatter.heroCta}
              </ButtonIconMove>
            </div>
              <div className="btn-group__item t-l b-l">
                  <Button variant="primary" colorVariant="outline-transparent" size="l" sizeResponsive={['m-l', 's-s']} href={data.pageData.frontmatter.heroPhoneHref}>
                      <SvgIcon size="16">
                          <IconCall />
                      </SvgIcon>
                      <span>{data.pageData.frontmatter.heroPhone}</span>
                  </Button>
              </div>
          </ButtonGroup>
        </Hero>
        <BillboardInfoOne className="t-xl b-l">
          <Typography variant="h2" className="t-s-m">
            {data.pageData.frontmatter.sliderTitle}
          </Typography>
          <Typography variant="txt2" isLead>
            {data.pageData.frontmatter.sliderSubtitle}
          </Typography>
        </BillboardInfoOne>
        <div className="t-l b-xl">
          <SlickSLider
            content={data.sliderContent.edges}
            additionalClassName="slick_home"
          />
        </div>
        <BillboardImageInfoOne
          className="t-xl b-xl"
          img={data.pageData.frontmatter.billboardImg}
        >
          <Typography variant="h2" className="t-s-m">
            {data.pageData.frontmatter.billboardTitle}
          </Typography>
          <Typography variant="txt2" isLead>
            {data.pageData.frontmatter.billboardSubtitle}
          </Typography>
          {data.featuresContent.edges.map((item) => (
            <div key={item.node.frontmatter.id}>
              <Typography variant="h4" className="t-m" dangerouslySetInnerHTML={{ __html: item.node.frontmatter.title }}/>
              <Typography variant="txt2" dangerouslySetInnerHTML={{ __html: item.node.frontmatter.text }}/>
            </div>
          ))}
        </BillboardImageInfoOne>
        <BillboardInfoTwo className="t-l t-s-l b-l b-s-l">
          <Typography variant="h2" isDisplay>
            {data.pageData.frontmatter.promoStat}
          </Typography>
          <Typography variant="txt2" isLead>
            {data.pageData.frontmatter.promoText}
          </Typography>
        </BillboardInfoTwo>
        <Cta
          title={data.pageData.frontmatter.ctaTitle}
          text={data.pageData.frontmatter.ctaSubtitle}
          className="t-l t-s-l b-l b-s-l"
          content={data.ctaContent.edges}
        />
        <div className="center col-12">
          <RedirectLink
            text={data.pageData.frontmatter.refer}
            link="https://www.hagerty.ca/"
            linkText={data.pageData.frontmatter.referBtn}
            className="t-l b-m"
          />
        </div>
        <div className="t-l b-l">
          <div className="center col-12 ta-l">
            <div className="center col-r-8-12 col-l-fixed-8 col-m-fw">
              <MarkdownTemplate>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.pageData.html,
                  }}
                />
              </MarkdownTemplate>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query (
    $absolutePath: String!
    $galleryPath: String!
    $featuresPath: String!
    $ctaPath: String!
  ) {
    pageData: markdownRemark(fileAbsolutePath: { regex: $absolutePath }) {
      frontmatter {
        metaTitle
        metaDescription
        headerBtn
        title
        subtitle
        heroCta
        heroHref
        heroPhone
        heroPhoneHref
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
        sliderTitle
        sliderSubtitle
        billboardTitle
        billboardSubtitle
        billboardImg {
          childImageSharp {
            gatsbyImageData
          }
        }
        promoStat
        promoText
        ctaTitle
        ctaSubtitle
        refer
        referBtn
      }
      html
    }
    featuresContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $featuresPath } }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            text
          }
        }
      }
    }
    ctaContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $ctaPath } }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            imageAlt
            icon
            text
            btnText
            btnIcon
            href
          }
        }
      }
    }
    sliderContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $galleryPath } }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            id
            alt
            description
            link
            img {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`

HomeTemplate.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.exact({
    pageData: PropTypes.exact({
      frontmatter: PropTypes.exact({
        headerBtn: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        heroCta: PropTypes.string,
        heroHref: PropTypes.string,
          heroPhone: PropTypes.string,
          heroPhoneHref: PropTypes.string,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        img: PropTypes.object,
        billboardImg: PropTypes.object,
        sliderTitle: PropTypes.string,
        sliderSubtitle: PropTypes.string,
        billboardTitle: PropTypes.string,
        billboardSubtitle: PropTypes.string,
        promoStat: PropTypes.string,
        promoText: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaSubtitle: PropTypes.string,
        refer: PropTypes.string,
        referBtn: PropTypes.string,
      }),
      html: PropTypes.any,
    }),
    sliderContent: PropTypes.exact({
      edges: PropTypes.array,
    }),
    featuresContent: PropTypes.exact({
      edges: PropTypes.array,
    }),
    ctaContent: PropTypes.exact({
      edges: PropTypes.array,
    }),
  }).isRequired,
}

export default HomeTemplate
