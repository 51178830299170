import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import * as styles from './styles.module.scss'

const BillboardInfoTwo = ({ direction = 'direct', children, className }) => {
  return (
    <article className={clsx(styles.billboardWrap, className)}>
      <div
        className={clsx(
          styles.billboard,
          direction === 'reverse' && styles.billboard__reverse,
          `center col-12`
        )}
      >
        <div className={clsx(styles.info, `center col-r-10-12 col-l-fw`)}>
          {children}
        </div>
      </div>
    </article>
  )
}

BillboardInfoTwo.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default BillboardInfoTwo
