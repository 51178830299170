import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { animateScroll as scroll } from 'react-scroll'
import clsx from 'clsx'
import * as styles from './styles.module.scss'
import SvgIcon from '../SvgIcon'
import IconMore from '../../icons/inline/icon-more.svg'

const Hero = ({ children, className, img, iconMore }) => {
  const image = getImage(img)
  const scrollBtn = React.createRef()
  const hero = React.createRef()
  const handleScroll = () => {
    const heroHeight = hero.current.offsetHeight
    const heroOffset = heroHeight + 56
    scroll.scrollTo(heroOffset, {
      smooth: true,
      duration: 300,
    })
  }
  return (
    <div ref={hero} className={clsx(styles.hero)}>
      <GatsbyImage
        image={image}
        alt="hero image"
        className={styles.heroImgWrap}
        imgClassName={styles.heroImg}
      />
      <div className={clsx(styles.center, 'center col-12')}>
        <div className={styles.heroContent}>{children}</div>
      </div>
      {iconMore && (
        <button
          onClick={handleScroll}
          ref={scrollBtn}
          type="button"
          className={styles.btnMore}
        >
          <SvgIcon size="48">
            <IconMore />
          </SvgIcon>
        </button>
      )}
    </div>
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  img: PropTypes.object.isRequired,
  iconMore: PropTypes.bool,
}

export default Hero
