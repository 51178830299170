import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import * as styles from './styles.module.scss'

const BillboardInfoOne = ({ direction = 'direct', children, className }) => {
  return (
    <article className={clsx(styles.billboardWrap, className)}>
      <div
        className={clsx(
          styles.billboard,
          direction === 'reverse' && styles.billboard__reverse,
          `center col-12`
        )}
      >
        <div
          className={clsx(
            styles.info,
            `col-r-6-12  col-l-r-6-10 col-m-r-5-6 col-s-fw`
          )}
        >
          {children}
        </div>
      </div>
    </article>
  )
}

BillboardInfoOne.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default BillboardInfoOne
