/* eslint-disable react/no-find-dom-node */
import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
// import debounce from 'lodash.debounce'
// import throttle from 'lodash.throttle'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import SvgIcon from 'components/SvgIcon'
import * as styles from './styles.module.scss'
import './slick.scss'
import './slick_home.scss'
import Typography from '../Typography'
import PrevIcon from '../../icons/inline/icon-arrow-left.svg'
import NextIcon from '../../icons/inline/icon-arrow-right.svg'

const Slide = ({ image, description, alt, link }) => {
  const slideImage = getImage(image)
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={clsx(styles.slider__slide)}
    >
      <Typography variant="h4" className={clsx(styles.slider__info)}>
        {description}
      </Typography>
      <div className={clsx(styles.slider__imgWrap)}>
        <GatsbyImage
          alt={alt}
          image={slideImage}
          className={styles.slider__imgWrapInner}
          imgClassName={styles.slider__img}
          objectFit="cover"
        />
      </div>
    </a>
  )
}

const PrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <button type="button" className={className} onClick={onClick}>
        <SvgIcon color="inherit" size="24">
            <PrevIcon />
        </SvgIcon>
    </button>
  )
}

const NextArrow = (props) => {
  const { className, onClick } = props
  return (
    <button type="button" className={className} onClick={onClick}>
        <SvgIcon color="inherit" size="24">
            <NextIcon />
        </SvgIcon>
    </button>
  )
}

const SlickSlider = ({ content, additionalClassName }) => {
  const settings = {
    dots: false,
    lazyLoad: 'progressive',
    infinite: false,
    nextArrow: <NextArrow className="slick-next" />,
    prevArrow: <PrevArrow className="slick-prev" />,
    speed: 500,
    variableWidth: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: 32,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          centerPadding: 16,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const slider = useRef()

  const scroll = (y) => {
    if (y > 0) {
      return slider.current.slickNext()
    }
    return slider.current.slickPrev()
  }

  // const scrollHandler = (e) => {
  //   const isTouchPad = e.wheelDeltaY
  //     ? e.wheelDeltaY === -3 * e.deltaY
  //     : e.deltaMode === 0
  //   if (isTouchPad) {
  //     scroll(e.deltaX)
  //   } else {
  //     scroll(e.deltaY)
  //   }
  // }

  // const sliderContainer = useCallback((node) => {
  //   if (node !== null) {
  //     node.addEventListener('wheel', (e) => {
  //       e.preventDefault()
  //       const throttled = throttle(scrollHandler, 300)
  //       throttled(e)
  //     })
  //   }
  // }, [])

  return (
    <section>
      <div className="center col-12">
        <div
          className={clsx(styles.slider__container, additionalClassName)}
          // ref={sliderContainer}
        >
          <Slider {...settings} content={content} ref={slider}>
            {content.map((item) => {
              const image = getImage(item.node.frontmatter.img)
              return (
                <Slide
                  key={item.node.frontmatter.id}
                  description={item.node.frontmatter.description}
                  image={image}
                  alt={item.node.frontmatter.alt}
                  link={item.node.frontmatter.link}
                />
              )
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}

SlickSlider.propTypes = {
  images: PropTypes.object,
  content: PropTypes.array,
  additionalClassName: PropTypes.string,
}

Slide.propTypes = {
  image: PropTypes.object,
  description: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
}

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default SlickSlider
