import React from 'react'
import PropTypes from 'prop-types'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import * as styles from './styles.module.scss'

const BillboardImageInfoOne = ({
  direction = 'direct',
  children,
  className,
  img,
}) => {
  const image = getImage(img)
  return (
    <article className={clsx(styles.billboardWrap, className)}>
      <div
        className={clsx(
          styles.billboard,
          direction === 'reverse' && styles.billboard__reverse,
          `center col-12`
        )}
      >
        <div
          className={clsx(
            styles.gallery,
            `col-r-6-12 col-l-r-5-10 col-m-r-4-6`
          )}
        >
          <GatsbyImage
            image={image}
            alt="alt image"
            className={clsx(styles.imgWrap)}
            imgClassName={clsx(styles.img)}
          />
        </div>
        <div
          className={clsx(styles.info, `col-r-6-12 col-l-r-5-10 col-m-r-4-6`)}
        >
          {children}
        </div>
      </div>
    </article>
  )
}

const propTypeImg = PropTypes.exact({
  props: PropTypes.object.isRequired,
  alt: PropTypes.string,
})

BillboardImageInfoOne.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  img: PropTypes.object.isRequired,
}

export default BillboardImageInfoOne
